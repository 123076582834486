import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { PaymentElement } from "@stripe/react-stripe-js";
import { StripeElements, Stripe } from "@stripe/stripe-js";
import React, { useState, forwardRef } from "react";
import styled from "styled-components";

import getLogger, { LogGroup } from "js/core/logger";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import withElements from "js/react/views/UserOptions/Billing/withElements";
import withStripe from "js/react/views/UserOptions/Billing/withStripe";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";

const logger = getLogger(LogGroup.BILLING);

interface ChangePaymentMethodDialogProps {
    closeDialog: (success: boolean) => void;
    elements: StripeElements;
    stripe: Stripe;
}

const PaymentError = styled.div`
    color: white;
    background: orangered;
    letter-spacing: 0px;
    padding: 8px 15px;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 600;
`;

export const ChangePaymentMethodDialog = BillingController.withInitializedState(withStripe(withElements(forwardRef(function ChangePaymentMethodDialog(props: ChangePaymentMethodDialogProps & BillingControllerState, ref: React.RefObject<BeautifulDialog>) {
    const { elements, closeDialog, stripe, stripeData: { subscription } } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const handleUpdatePaymentMethod = async () => {
        setIsSubmitting(true);
        setError(null);

        const { error: submitError } = await elements.submit();
        if (submitError) {
            logger.error(new Error(submitError.message), "elements.submit() failed");
            setError(submitError.message);
            setIsSubmitting(false);
            return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({ elements });
        if (error) {
            logger.error(new Error(error.message), "stripe.createPaymentMethod() failed");
            setIsSubmitting(false);
            setError(error.message);
            return;
        }

        try {
            await BillingController.updatePaymentMethod(paymentMethod.id);

            if (subscription.status !== "past_due") {
                closeDialog(true);
                return;
            }

            await BillingController.payLatestInvoiceOnPastDueSubscription();

            closeDialog(true);
        } catch (err) {
            logger.error(err, "Api.paymentMethods.post() failed", { paymentMethodId: paymentMethod?.id });
            setError(err.message);
        }

        setIsSubmitting(false);
    };

    const hadleCloseDialog = () => {
        closeDialog(false);
    };

    return (
        <BeautifulDialog preventClose closeDialog={hadleCloseDialog} ref={ref}>
            <FetchingClickShield visible={isSubmitting} backgroundColor="white" />
            <DialogTitle>Update Payment Method</DialogTitle>
            <DialogContent>
                <PaymentElement />
                {error && <PaymentError>{error}</PaymentError>}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={isSubmitting} onClick={hadleCloseDialog}>
                    Cancel
                </Button>
                <Button color="primary" disabled={isSubmitting} onClick={handleUpdatePaymentMethod} data-test-id="update-payment-method-button">
                    Update Payment Method
                </Button>
            </DialogActions>
        </BeautifulDialog >
    );
}))));
