import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Icon
} from "@material-ui/core";

import FetchingClickShield from "js/react/components/FetchingClickShield";
import { ChangePaymentMethodDialog } from "js/react/views/UserOptions/Billing/ChangePaymentMethodDialog";
import { BeautifulDialog, ShowErrorDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import withStripe from "js/react/views/UserOptions/Billing/withStripe";
import getLogger, { LogGroup } from "js/core/logger";

import "css/billing.scss";

const logger = getLogger(LogGroup.BILLING);

const AlertContainer = styled.div`
  color: red;
  font-size: 15px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
`;

const UpdatePaymentMessage = styled(DialogContent)`
  padding: 0 34px 20px !important;
`;

const UpdatePaymentActions = styled(DialogActions)`
  padding: 0 34px 20px !important;
`;

class PaymentIssueDialog extends Component {
    state = {
        isSubmitting: false
    };

    handleUpdatePaymentMethod = async () => {
        const { closeDialog, callback = () => { } } = this.props;

        this.setState({ isSubmitting: true });

        const success = await ShowDialogAsync(ChangePaymentMethodDialog);

        if (success) {
            callback(true);
            closeDialog();
            return;
        }

        this.setState({ isSubmitting: false });
    }

    handleConfirmPayment = async () => {
        const { closeDialog, stripe, charge, callback = () => { } } = this.props;

        this.setState({ isSubmitting: true });

        try {
            const { error } = await stripe.handleCardPayment(charge.client_secret, {
                payment_method: charge.payment_method
            });

            if (error) {
                throw error;
            }

            callback(true);
            closeDialog();
        } catch (err) {
            this.setState({ isSubmitting: false });
            logger.error(err, "PaymentIssueDialog stripe.handleCardPayment() failed");
            ShowErrorDialog({
                error: "Could not confirm payment",
                message: err.message
            });
        }
    }

    render() {
        const { closeDialog, subscription, charge, callback = () => { } } = this.props;
        const { isSubmitting } = this.state;

        const failureMessage = charge?.failure_message;
        const nextAmount = charge?.amount;

        const nextInvoiceDate = moment.unix(subscription.currentPeriodEnd ?? subscription.current_period_end)
            .format("MMM DD, YYYY");

        const daysUntilDowngrade = moment.unix(subscription.currentPeriodStart ?? subscription.current_period_start)
            .add(30, "days")
            .diff(moment(), "days");

        const required2fa = charge?.failure_code === "requires_action";

        const onClose = () => {
            callback(false);
            closeDialog();
        };

        return (
            <BeautifulDialog className="update_payment_form" closeDialog={onClose}>
                <FetchingClickShield visible={isSubmitting} backgroundColor="white" />
                <DialogTitle>
                    {required2fa && <>
                        <AlertContainer>
                            <Icon>error</Icon>
                            <span style={{ paddingLeft: 10 }}>Please confirm your payment</span>
                        </AlertContainer>
                    </>}
                    {!required2fa && <>
                        <AlertContainer>
                            <Icon>error</Icon>
                            <span style={{ paddingLeft: 10 }}>Payment Failed</span>
                        </AlertContainer>
                        <div style={{ paddingTop: 20 }}>Don't lose your work!</div>
                    </>}
                </DialogTitle>
                <UpdatePaymentMessage>
                    {required2fa && <>
                        <DialogContentText>
                            Your bank required additional verification to process your payment.
                        </DialogContentText>
                    </>}
                    {!required2fa && <>
                        <DialogContentText>
                            There was a problem with your payment method.
                        </DialogContentText>
                        <DialogContentText>
                            You have <b>{daysUntilDowngrade} days</b> to update your payment information before
                            your account is downgraded. Learn more about managing your
                            account <a
                                href="https://support.beautiful.ai/hc/en-us/sections/360006956931-Managing-your-Billing"
                                target="_blank">here</a>.
                        </DialogContentText>
                        <DialogContentText>
                            <b>Failure Reason:</b> {failureMessage}
                        </DialogContentText>
                    </>}
                    <DialogContentText>
                        You will be charged ${nextAmount / 100} today. Your next bill will be due {nextInvoiceDate}.
                    </DialogContentText>
                </UpdatePaymentMessage>
                <UpdatePaymentActions>
                    <Button color="secondary" onClick={onClose}>
                        Dismiss
                    </Button>
                    {required2fa &&
                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            onClick={() => this.handleConfirmPayment()}>
                            Confirm payment
                        </Button>
                    }
                    {!required2fa &&
                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            onClick={() => this.handleUpdatePaymentMethod()}>
                            Update Payment Method
                        </Button>
                    }
                </UpdatePaymentActions>
            </BeautifulDialog>
        );
    }
}

export default withStripe(PaymentIssueDialog);
